import React, { useMemo } from "react"
import {
  Crosshair,
  Hint,
  HorizontalGridLines,
  makeWidthFlexible,
  MarkSeries,
  VerticalGridLines,
  WhiskerSeries,
  XAxis,
  XYPlot,
} from "react-vis"
import { Dayjs } from "dayjs"
import { ClientsSummary } from "@today/api/tracker"
import { StatisticsBox } from "./StatisticsBox"

const FlexibleXYPlot = makeWidthFlexible(XYPlot)

type ClientsSummaryInfoProps = {
  clientsSummary: ClientsSummary
  startTime: Dayjs
  endTime: Dayjs
}

export function ClientsSummaryInfo({
  clientsSummary,
  startTime,
  endTime,
}: ClientsSummaryInfoProps) {
  const diffWorkingDays = useMemo(() => {
    const diff = endTime.diff(startTime, "days")
    let days = 0
    for (
      let i = startTime.isoWeekday();
      i < startTime.isoWeekday() + diff;
      i++
    ) {
      if (i % 7 === 6 || i % 7 === 0) {
        continue
      }
      days++
    }
    return Math.max(1, days)
  }, [startTime, endTime])
  return (
    <div className="mt-4 grid grid-cols-1 gap-x-2 md:grid-cols-7">
      <StatisticsBox title="활성 출고 고객사" bg="success">
        <p>총 {clientsSummary.takingOutClientsCount.toLocaleString()}곳</p>
        <p className="mt-2 text-center text-sm text-gray-500">
          기간 내 출고를 1회 이상 수행한 고객사
        </p>
      </StatisticsBox>
      <StatisticsBox title="총 출고수">
        <p>총 {clientsSummary.takingOutLoadsTotalCount.toLocaleString()}건</p>
        <p className="mt-2 text-center text-sm text-gray-500">
          집화처리: {clientsSummary.collectedLoadsTotalCount.toLocaleString()}건
          <br />
          배송완료: {clientsSummary.deliveredLoadsTotalCount.toLocaleString()}건
        </p>
      </StatisticsBox>
      <StatisticsBox title="고객사 평균 출고수">
        <p>
          평균{" "}
          {(
            clientsSummary.takingOutLoadsTotalCount /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건
        </p>
        <p className="mt-2 text-center text-sm text-gray-500">
          집화처리:{" "}
          {(
            clientsSummary.collectedLoadsTotalCount /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건<br />
          배송완료:{" "}
          {(
            clientsSummary.deliveredLoadsTotalCount /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건
        </p>
      </StatisticsBox>
      <StatisticsBox title="고객사 일평균 출고수">
        <p>
          일{" "}
          {(
            clientsSummary.takingOutLoadsTotalCount /
            diffWorkingDays /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건
        </p>
        <p className="mt-2 text-center text-sm text-gray-500">
          집화처리:{" "}
          {(
            clientsSummary.collectedLoadsTotalCount /
            diffWorkingDays /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건<br />
          배송완료:{" "}
          {(
            clientsSummary.deliveredLoadsTotalCount /
            diffWorkingDays /
            Math.max(1, clientsSummary.takingOutClientsCount)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          건
        </p>
      </StatisticsBox>
      <div className="flex gap-x-2 rounded-lg border bg-white px-5 py-1 text-center md:col-span-3">
        <div className="flex flex-1 flex-col items-center justify-center gap-y-1">
          <FlexibleXYPlot height={160} margin={{ left: 15, bottom: 30 }}>
            <VerticalGridLines tickTotal={10} />
            <XAxis tickTotal={10} />
            <MarkSeries
              color="#12939a"
              strokeWidth={1}
              data={[
                {
                  y: 0,
                  size: 3,
                  x:
                    clientsSummary.takingOutLoadsTotalCount /
                    Math.max(1, clientsSummary.takingOutClientsCount),
                },
              ]}
            />
            <WhiskerSeries
              data={[
                {
                  x:
                    clientsSummary.takingOutLoadsTotalCount /
                    Math.max(1, clientsSummary.takingOutClientsCount),
                  y: 0,
                  xVariance: 2 * clientsSummary.takingOutLoadsStandardDeviation,
                },
              ]}
              color="#12939a"
              strokeWidth={8}
            />
            <WhiskerSeries data={[{ x: 0, y: -1 }]} />
            <WhiskerSeries
              data={[{ x: clientsSummary.takingOutLoadsMaxCount * 1.05, y: 1 }]}
            />
            <Crosshair
              values={[
                {
                  x:
                    clientsSummary.takingOutLoadsTotalCount /
                    Math.max(1, clientsSummary.takingOutClientsCount),
                },
              ]}
              style={{ line: { backgroundColor: "black" } }}
            >
              <h3 className="whitespace-nowrap text-left text-base text-black">
                <strong>고객사 평균 출고수</strong>
                <br />
                <small>
                  {(
                    clientsSummary.takingOutLoadsTotalCount /
                    Math.max(1, clientsSummary.takingOutClientsCount)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  건
                </small>
              </h3>
            </Crosshair>
            <Hint
              value={{
                y: 0,
                x:
                  clientsSummary.takingOutLoadsStandardDeviation +
                  clientsSummary.takingOutLoadsTotalCount /
                    Math.max(1, clientsSummary.takingOutClientsCount),
              }}
            >
              <h3 className="mt-1 whitespace-nowrap text-left text-base text-[#12939a]">
                <strong>표준편차</strong>{" "}
                {clientsSummary.takingOutLoadsStandardDeviation.toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </h3>
            </Hint>
            <Crosshair
              values={[{ x: clientsSummary.takingOutLoadsMinCount }]}
              style={{ line: { backgroundColor: "orange" } }}
            >
              <div />
            </Crosshair>
            <Hint value={{ y: -1, x: clientsSummary.takingOutLoadsMinCount }}>
              <h3 className="mx-2 whitespace-nowrap text-left text-base text-orange-500">
                <strong>최소 출고 고객사</strong>{" "}
                <small>
                  {clientsSummary.takingOutLoadsMinCount.toLocaleString()}건
                </small>
              </h3>
            </Hint>
            <Crosshair
              values={[{ x: clientsSummary.takingOutLoadsMaxCount }]}
              style={{ line: { backgroundColor: "red" } }}
            >
              <h3 className="whitespace-nowrap text-right text-base text-red-500">
                <strong>최대 출고 고객사</strong>
                <br />
                <small>
                  {clientsSummary.takingOutLoadsMaxCount.toLocaleString()}건
                </small>
              </h3>
            </Crosshair>
          </FlexibleXYPlot>
        </div>
      </div>
    </div>
  )
}
