import dayjs from "dayjs"
import { useClientsSummary, useEventCounts, useStats } from "../../useStats"
import { useEffect, useMemo, useState } from "react"
import { Spinner } from "baseui/spinner"
import { EventCountsGraph } from "./EventCountsGraph"
import {
  EventCountsWindowSizeSetting,
  useWindowSizeSecs,
} from "./EventCountsWindowSizeSetting"
import ko from "date-fns/locale/ko"
import { DateRangePicker } from "./DateRangePicker"
import { RealTimeInfo } from "./RealTimeInfo"
import { DateRangeInfo } from "./DateRangeInfo"
import { dateOptions } from "../../utils/date-range"
import { EventCountTable } from "./EventCountTable"
import { ButtonGroupSelector } from "./ButtonGroupSelector"
import { DELIVERY_CLASSES, DeliveryClass } from "@today/api/common"
import { getDeliveryClassName } from "@today/lib/utils"
import { ClientsSummaryInfo } from "./ClientsSummary"
import { FilterItem } from "../FilterItem"
import { useClientSelector } from "./useClientSelector"
import { useRouter } from "next/router"

export function MainDashboard() {
  const router = useRouter()
  const today = useMemo(() => {
    const now = dayjs()
    return now.startOf("day").add(now.hour() < 9 ? -1 : 0, "day")
  }, [])
  const [targetDateRange, setTargetDateRange] = useState([today, today])
  const [targetStartDate, targetEndDate] = targetDateRange

  const isToday = today.isSame(targetStartDate) && today.isSame(targetEndDate)
  const isSameDay = targetStartDate.isSame(targetEndDate)

  const startTime = targetStartDate.add(9, "hours")
  const endTime = targetEndDate.add(9 + 24, "hours")

  const [deliveryClasses, setDeliveryClasses] = useState<DeliveryClass[]>()
  const [siDos, setSiDos] = useState<string[]>()

  const {
    clientId,
    clientOptions,
    selectedValue: selectedClientValue,
    setSelectedValue: setSelectedClientValue,
    isLoading: isLoadingClients,
  } = useClientSelector(router.query.clientId as string | undefined)

  const { stats: targetDeliverTimeStats } = useStats(
    {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      timeIntervalTarget: "target_deliver_time",
      deliveryClasses,
      receiverSiDos: siDos,
      clientId,
    },
    60
  )
  const { stats: eventTimeStats } = useStats(
    {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      timeIntervalTarget: "event_time",
      deliveryClasses,
      receiverSiDos: siDos,
      clientId,
    },
    120
  )

  const [windowSizeSecs, onChangeWindowSize] = useWindowSizeSecs()
  const { eventTimeWindows: eventTimeWindows } = useEventCounts(
    {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      windowSizeSecs,
      windowTarget: "event_time",
      timeIntervalTarget: "event_time",
      deliveryClasses,
      senderSiDos: siDos,
      clientId,
    },
    180
  )
  const { eventTimeWindows: targetDeliverTimeWindows } = useEventCounts(
    {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      windowSizeSecs,
      windowTarget: "event_time",
      timeIntervalTarget: "target_deliver_time",
      deliveryClasses,
      receiverSiDos: siDos,
      clientId,
    },
    180
  )
  const { eventTimeWindows: dailyWindows } = useEventCounts(
    {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      windowSizeSecs: 3600 * 24,
      windowTarget: "target_deliver_time",
      timeIntervalTarget: "target_deliver_time",
      deliveryClasses,
      receiverSiDos: siDos,
      clientId,
    },
    180
  )
  const { clientsSummary, isLoading: isClientsSummaryLoading } =
    useClientsSummary(
      {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        deliveryClasses,
        receiverSiDos: siDos,
        clientId,
      },
      120
    )

  // 아래는 window size만 바꾸는 경우에도 event counts의 결과값은 변하지 않으므로 캐싱된 정보를 배송현황에 계속 보여주기 위함
  const [eventTimeWindowsCache, setEventTimeWindowsCache] =
    useState(eventTimeWindows)
  useEffect(() => {
    setEventTimeWindowsCache(undefined)
  }, [targetDateRange, deliveryClasses, clientId])
  useEffect(() => {
    if (!eventTimeWindowsCache && eventTimeWindows) {
      setEventTimeWindowsCache(eventTimeWindows)
    }
  }, [eventTimeWindows, eventTimeWindowsCache])

  // 모바일 환경에서는 date picker 1달만 표시
  const [monthsShown, setMonthsShown] = useState(2)
  useEffect(() => {
    setMonthsShown(window.innerWidth < 1024 ? 1 : 2)
  }, [])

  return (
    <div className="flex h-full w-full flex-col overflow-y-scroll p-8">
      <div className="flex flex-col justify-between gap-y-4 lg:flex-row">
        <div className="flex flex-col gap-x-5 gap-y-2 lg:flex-row lg:items-center">
          <div className="text-2xl font-semibold">
            {isToday
              ? "오늘의 배송 현황"
              : `${targetStartDate.format("YYYY년 M월 D일 (dd)")}${
                  isSameDay ? "" : ` ~ ${targetEndDate.format("M월 D일 (dd)")}`
                } 배송 통계`}
          </div>
          <ButtonGroupSelector
            onChange={(deliveryClasses) => setDeliveryClasses(deliveryClasses)}
            availableOptions={DELIVERY_CLASSES}
            getLabel={getDeliveryClassName}
          />
          <span className="h-4 border-l border-gray-500" />
          <ButtonGroupSelector
            onChange={(siDos) => setSiDos(siDos)}
            availableOptions={["서울", "인천", "경기"]}
            getLabel={(item: string) => item}
          />
        </div>
        <div className="flex flex-col gap-x-3 gap-y-1 md:flex-row md:items-center">
          {!isLoadingClients && (
            <div className="flex w-96 flex-col">
              <FilterItem
                label="특정 고객사 선택"
                options={clientOptions}
                value={selectedClientValue}
                setValue={setSelectedClientValue}
                radio
                labelPosition="left"
              />
            </div>
          )}
          <p className="text-sm md:text-base">조회 기간</p>
          <div className="w-full md:w-64">
            <DateRangePicker
              quickSelect
              locale={ko}
              initialRange={[today.toDate(), today.toDate()]}
              onChange={(range) => {
                // XXX: 윈도우 사이즈가 기존 작은 값으로 남은 상태에서 date range가 변해 불필요한 거대 API 콜이 일어나는 것을 방지
                onChangeWindowSize(0)
                setTargetDateRange([dayjs(range[0]), dayjs(range[1])])
              }}
              monthsShown={monthsShown}
              filterDate={(day) => dayjs(day).startOf("day").isBefore(dayjs())}
              quickSelectOptions={dateOptions}
            />
          </div>
        </div>
      </div>
      <div>
        {isToday ? (
          targetDeliverTimeStats && eventTimeStats ? (
            <RealTimeInfo
              targetDeliverTimeStats={targetDeliverTimeStats}
              eventTimeStats={eventTimeStats}
            />
          ) : (
            <Spinner />
          )
        ) : dailyWindows && eventTimeWindowsCache ? (
          <DateRangeInfo
            dailyWindows={dailyWindows}
            eventTimeWindows={eventTimeWindowsCache}
          />
        ) : (
          <Spinner />
        )}
      </div>
      <div className="mt-2 text-gray-500">
        <p>
          배송 완료율, SLI 준수율 등 배송과 관련된 메트릭은 조회 기간 내에
          배송을 완료해야 하는 화물들을 대상으로 값이 산출됩니다. 따라서 조회
          기간 이전 밀린배송을 조회 기간 내 처리하는 경우 등은 통계에 잡히지
          않을 수 있습니다.
        </p>
        <p>
          반면, {isToday ? "오늘 집화" : "총 집화 물품"}의 경우 조회 기간 내에
          발생한 픽업/인수 이벤트가 모두 집계됩니다.
        </p>
        {isToday && (
          <p>
            스캔이 되지 않은 물품은 오늘 내 배송 대상에 아직 포함되지 않습니다.
            집화 대기 중 물품은 허주문을 최소화하기 위해 최근 1주일 간을
            기준으로 합니다. 배송 완료율은 오늘 내 배송 대상을 모수로
            계산됩니다.
          </p>
        )}
      </div>
      <div className="mb-5 mt-10 text-xl font-semibold">활성 고객사 통계</div>
      {isClientsSummaryLoading ? (
        <Spinner />
      ) : clientsSummary ? (
        <ClientsSummaryInfo
          clientsSummary={clientsSummary}
          startTime={startTime}
          endTime={endTime}
        />
      ) : null}
      <div className="mt-2 text-gray-500">
        <p>
          출고 시간(픽업 가능 시간) 기준으로 계산되는 통계이므로, 주문 시간 및
          배송 완료 예정 시간 기준으로 계산되는 실시간 통계와 일치하지 않을 수
          있습니다. 일평균 출고수는 1주일 = 주중 5일로 단순 계산하여 나눈
          값입니다.
        </p>
        <p>
          위 운송 서비스에서 <strong>SME당일/SME일반/택배위탁</strong>을
          선택하고 일/주/월 단위로 조회 기간을 선택하면 인천 SME 고객사에 대한
          DAU/WAU/MAU 등을 확인하실 수 있습니다.
        </p>
      </div>
      <div className="mb-5 mt-10 flex flex-col justify-between gap-y-2 md:flex-row">
        <div className="text-xl font-semibold">배송 타임라인</div>
        <EventCountsWindowSizeSetting
          startTime={startTime}
          endTime={endTime}
          onChangeWindowSize={onChangeWindowSize}
        />
      </div>
      {eventTimeWindows ? (
        <EventCountsGraph eventWindows={eventTimeWindows} />
      ) : (
        <Spinner />
      )}
      <div className="mt-12">
        <div className="mb-4 flex justify-between">
          <div className="text-xl font-semibold">시간별 통계 데이터</div>
        </div>
        {eventTimeWindows && targetDeliverTimeWindows && dailyWindows ? (
          <EventCountTable
            eventTimeWindows={eventTimeWindows}
            targetDeliverTimeWindows={targetDeliverTimeWindows}
            dailyWindows={dailyWindows}
            hideFuture
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}
